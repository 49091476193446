import * as Sentry from '@sentry/vue';
import * as pkg from '~/package.json';

export default defineNuxtPlugin((nuxtApp) => {
  console.log('Starting Sentry client-side configuration');
  const { vueApp } = nuxtApp;
  Sentry.init({
    app: vueApp,
    dsn: useRuntimeConfig().public.sentry.dsn,
    environment: useRuntimeConfig().public.sentry.environment,
    release: `${pkg.name}@${pkg.version}`,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    // Tracing
    // We recommend adjusting this value in production, or using a tracesSampler for finer control.
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/sentry-nuxt3\.pages\.dev/],
    // Session Replay
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
});