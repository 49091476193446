import { default as addressmVOCssY6JyMeta } from "/opt/buildhome/repo/pages/address.vue?macro=true";
import { default as contactsYIAlohSNptMeta } from "/opt/buildhome/repo/pages/contacts.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: "address",
    path: "/address",
    component: () => import("/opt/buildhome/repo/pages/address.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/opt/buildhome/repo/pages/contacts.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  }
]